@page {
  size: landscape;
}

@media print {
  #fb-stats {
    page-break-inside: avoid;
  }

  #ig-stats {
    page-break-inside: avoid;
  }

  #twitter-stats {
    page-break-inside: avoid;
  }

  #metrics {
    display: flex;
    flex-direction: column;
  }

  #age-gender {
    display: flex !important;
    flex-direction: column;
  }

  #age-gender > :first-child {
    margin-bottom: 320px;
  }

  #age-gender > :last-child {
    margin-bottom: 200px;
  }

  #footer > * {
    line-height: 24px;
  }

  #container {
    width: 98%;
  }

  [id^="facebook-Top"],
  [id^="instagram-Top"],
  [id^="twitter-Top"] {
    height: 650px;
  }

  [id^="facebook-Audience by Age"] > :first-child > :last-child > *,
  [id^="instagram-Audience by Age"] > :first-child > :last-child > * {
    margin-left: -30px;
  }

  [id^="instagram-Total impressions"] {
    margin-bottom: 320px !important;
  }

  [id^="instagram-Audience by Age"] {
    margin-bottom: 90px !important;
  }

  #metrics {
    display: flex;
    flex-direction: column;
  }

  #metrics > *:not(:last-child) {
    margin-bottom: 320px;
  }

  #metrics > :last-child {
    margin-bottom: 300px;
  }

  #city-country {
    background: unset;
  }

  #city-country > :first-child {
    background: #fff;
    border-radius: 15px !important;
    padding: 80px 0;
  }

  #city-country > :last-child > div:first-child {
    border: none !important;
    background: none;
  }

  #top5 {
    margin-top: 200px;
    background: #fff;
    border-radius: 15px !important;
  }
}
