* {
  scrollbar-width: 7px;
  scrollbar-color: #e9e9e9 #abadad;
}
*::-webkit-scrollbar {
  width: 7px;
}
*::-webkit-scrollbar-track {
  background: #e9e9e9;
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb {
  background-color: #abadad;
  border-radius: 10px;
}
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: "Avenir Book", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f2f2f2;
}
li {
  list-style: none;
}
code {
  font-family: "Avenir Book", sans-serif;
}

#root {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

.body-signin {
  background-color: #000;
  color: #fff;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Avenir Black", sans-serif;
}
input:-webkit-autofill {
  box-shadow: 0 0 0 30px white inset !important;
}
/*custom fonts */
@font-face {
  font-family: "AvenirNext";
  src: url("./static/fonts/AvenirNextCyr-Regular.eot") format("eot"); /*1E9*/
  src: local("AvenirNext"),
    url("./static/fonts/AvenirNextCyr-Regular.eot?#iefix") format("eot"),
    /*1E6-8*/ url("./static/fonts/AvenirNextCyr-Regular.ttf") format("ttf"),
    url("./static/fonts/AvenirNextCyr-Regular.woff") format("woff"),
    url("./static/fonts/AvenirNextCyr-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "AvenirNext-Medium";
  src: url("./static/fonts/AvenirNextCyr-Medium.eot") format("eot"); /*1E9*/
  src: local("AvenirNext-Medium"),
    url("./static/fonts/AvenirNextCyr-Medium.eot?#iefix") format("eot"),
    /*1E6-8*/ url("./static/fonts/AvenirNextCyr-Medium.ttf") format("ttf"),
    url("./static/fonts/AvenirNextCyr-Medium.woff") format("woff"),
    url("./static/fonts/AvenirNextCyr-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "AvenirNext-DemiBold";
  src: url("./static/fonts/AvenirNextCyr-Demi.eot") format("eot"); /*1E9*/
  src: local("AvenirNext-DemiBold"),
    url("./static/fonts/AvenirNextCyr-Demi.eot?#iefix") format("eot"),
    /*1E6-8*/ url("./static/fonts/AvenirNextCyr-Demi.ttf") format("ttf"),
    url("./static/fonts/AvenirNextCyr-Demi.woff") format("woff"),
    url("./static/fonts/AvenirNextCyr-Demi.woff2") format("woff2");
}
@font-face {
  font-family: "Avenir Black";
  src: url("./static/fonts/Avenir-Black.eot") format("eot");
  src: local("Avenir Black"),
    url("./static/fonts/Avenir-Black.ttf") format("ttf");
}
@font-face {
  font-family: "Avenir Book";
  src: url("./static/fonts/Avenir-Book.eot") format("eot");
  src: local("Avenir Book"), url("./static/fonts/Avenir-Book.ttf") format("ttf");
}
@font-face {
  font-family: "Futura Bold";
  src: url("./static/fonts/Futura Bold.ttf") format("ttf");
  src: local("Futura Bold");
}
@font-face {
  font-family: "Futura Light";
  src: url("./static/fonts/Futura Light.ttf") format("ttf");
  src: local("Futura Light");
}
#print-client {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.align {
  display: flex;
  justify-content: flex-end !important;
}
.root {
  width: 100%;
  padding-bottom: 50px;
  font-family: "Avenir Black", sans-serif;
}

@media screen and (max-width: 600px) {
  #date-picker {
    width: 96%;
  }

  #container {
    width: 95% !important;
  }
}

@media screen and (max-width: 830px) {
  #be-measured {
    margin: 103px;
  }
  #selector > :first-child {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .align {
    display: flex;
    justify-content: center !important;
  }

  #client-list > :last-child > :first-child {
    margin-right: 0 !important;
  }

  #print-client {
    margin-top: -22px;
  }
}

@media screen and (max-width: 768px) {
  #container {
    width: 85%;
  }
  #root-analytics {
    padding: 0 10px;
  }

  #navbar {
    padding: 0 10px;
  }
  #date-picker {
    flex-wrap: nowrap;
    margin-top: 20px;
  }
  #print-client {
    margin-top: -22px;
  }

  body,
  html {
    overflow-x: hidden;
    width: 100%;
    scroll-behavior: smooth;
  }
  #metrics {
    grid-template-columns: unset !important;
    display: unset !important;
    grid-gap: 0;
  }
  #city-country {
    grid-template-columns: unset !important;
    grid-gap: 0;
    width: auto !important;
    padding: 0px;
  }
  #city-country > * {
    width: 98%;
  }
  #selector {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  #client-list > :last-child div div[class*="autoCompleteField"] {
    margin-right: 0px !important;
  }
  div[id*="mediaContainer"] {
    width: 100% !important;
    margin-right: 0px;
  }
  #profileContainer {
    margin-top: 40px;
  }
  #top5 {
    padding: 8px 17px 0px 2px;
    grid-template-columns: unset !important;
  }
  #empty-analytics {
    text-align: center;
  }
}

@media screen and (max-width: 1150px) {
  #age-gender {
    display: unset !important;
    grid-template-columns: unset !important;
    grid-gap: 0;
  }
  #media {
    flex-flow: row wrap;
  }
  div[id*="mediaContainer"] {
    width: calc(100% / 2 - 20px);
    margin-bottom: 34.5px;
  }
  div[id*="mediaContainer"]:nth-child(2) {
    margin-right: 0px;
  }
}

@media screen and (max-width: 1420px) {
  .be-planned-title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: auto;
  }
  .be-planned-title > :first-child {
    margin-bottom: 30px;
    margin-top: 0px !important;
  }
}

@media screen and (max-width: 930px) {
  #page-title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  #page-title > :first-child {
    margin-bottom: 30px;
    margin-top: 0px !important;
    /* text-align: center; */
  }

  #selector {
    align-items: center;
  }
}

@media screen and (max-width: 420px) {
  #loader {
    justify-content: flex-start !important;
    margin-left: 22%;
  }
  #admin-title > :last-child {
    display: flex;
    flex-direction: column;
  }

  #admin-title > :last-child > :first-child {
    width: fit-content;
    margin-bottom: 10px;
  }

  #admin-settings {
    padding-top: 32px;
  }

  #profileContainer {
    padding: 20px;
  }

  .be-planned-title > :first-child {
    padding-top: 32px !important;
  }

  #admin-table {
    margin-top: 60px !important;
  }
}

@media screen and (max-width: 455px) {
  #print-client {
    width: 296px;
  }

  #date-picker {
    width: 95%;
  }

  #popup {
    width: 338px !important;
  }

  .be-planned-title > :first-child {
    margin-top: 32px !important;
  }
}

@media screen and (max-width: 570px) {
  #selector {
    margin-top: 0 !important;
  }
  #selector > :last-child {
    flex-direction: column;
    align-items: center !important;
  }
  #edit-create {
    width: 98% !important;
  }
  #popup {
    width: 452px;
  }
}

@media screen and (max-height: 680px) {
  #post-section {
    height: 490px;
  }
}
@media screen and (max-width: 350px) {
  #post-section {
    height: 410px;
  }
}
@media screen and (max-width: 600px) {
  #admin-title {
    display: flex;
    flex-direction: column;
  }

  #admin-title > :last-child {
    margin: 20px 0;
  }

  #admin-table {
    margin-top: 100px;
  }
}

@media screen and (max-width: 530px) {
  #action-btn {
    width: 160px !important;
    max-width: unset !important;
  }
}

@media screen and (max-width: 460px) {
  #create-btn {
    padding: "15px 20px";
  }

  #content-post > :last-child {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 390px) {
  #platform {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  #create-btn {
    margin-top: 20px;
  }
}

@media screen and (max-width: 800px) {
  #stats {
    margin-left: 0px;
  }
}
a[title="Interactive JavaScript maps"] {
  display: none !important;
}
@media screen and (max-width: 1000px) {
  #analytics > :last-child {
    display: flex;
    flex-direction: column;
  }
  #container {
    padding-top: 70px;
  }
}

@media screen and (min-width: 1600px) {
  #metrics {
    grid-template-columns: 1fr 1fr;
  }
  #city-country {
    grid-template-columns: 1fr 1fr !important;
  }
}

@media screen and (max-width: 1520px) {
  #action-btn {
    width: 156px;
  }
}

@media screen and (max-width: 1220px) {
  #action-btn {
    width: 118px !important;
  }
}

@media screen and (max-width: 900px) {
  #content-post {
    flex-direction: column;
    font-size: 20px;
  }

  #content-post > :first-child {
    max-width: 100% !important;
    margin-bottom: 24px;
  }
  #content-post > :first-child > div {
    margin-right: 0;
  }
  #content-post > :last-child {
    max-width: 100%;
  }

  #action-btn {
    width: 200px !important;
  }
  div[class*="actionbtns"] {
    max-width: 410px;
  }
}
