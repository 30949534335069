/* styles.css or App.css */
.chart-container {
  width: 100%;
  max-width: 100%;
  padding: 20px; /* Optional: for spacing */
  box-sizing: border-box;
}

h2 {
  font-family: "Avenir";
  font-size: 24px;
  line-height: 48px;
  text-align: left;
  font-weight: 900;
}
